import { useState, FormEvent } from "react"

import { CognitoUserAttribute } from "amazon-cognito-identity-js"

import UserPool from "../UserPool"

import { AccountContext } from "./Account"

function SignUp({ isLogin = false }: { isLogin?: boolean }) {
  const [user, setUser] = useState("")
  const [pass, setPass] = useState("")

  const { authenticate } = AccountContext()

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (isLogin) {
      authenticate?.(user, pass)
        .then((reply) => {
          console.log(reply)
        })
        .catch((error) => {
          console.log(error)
        })
    } else {
      let attributeList = []

      var attributeEmail = new CognitoUserAttribute({
        Name: "email",
        Value: user,
      })

      attributeList.push(attributeEmail)

      UserPool.signUp(user, pass, attributeList, [], (err, data) => {
        if (err) {
          console.error(err)
        }
        console.log(data)
      })
    }
  }

  return (
    <div>
      <h1>{isLogin ? "Log In" : "Sign Up"}</h1>
      <form onSubmit={onSubmit}>
        <label htmlFor="username">Username</label>
        <input value={user} onChange={(event) => setUser(event.target.value)} />
        <br />
        <label htmlFor="password">Password</label>
        <input value={pass} onChange={(event) => setPass(event.target.value)} />
        <br />
        <button type="submit">Submit</button>
      </form>
    </div>
  )
}

export default SignUp
