import { createContext, ReactNode, useContext, useEffect, useState } from "react"

import { AuthenticationDetails, CognitoUser, CognitoUserAttribute, CognitoUserSession } from "amazon-cognito-identity-js"

import Pool from "../UserPool"

interface IAccountContext {
  loggedIn: boolean
  authenticate: (Username: string, Password: string) => Promise<unknown>
  logOut: () => void
  changePassword: (oldPassword: string, newPassword: string) => void
}

const InitialContext = createContext<Partial<IAccountContext>>({})

export const AccountContext = () => useContext(InitialContext)

export const AccountWrapper = ({ children }: { children: ReactNode }) => {
  const [loggedIn, setLoggedIn] = useState(false)

  const getSession = async () => {
    return await new Promise<{
      user: CognitoUser
      session: CognitoUserSession
      attributes: {
        email: string
        email_verified: boolean
        sub: string
      }
    }>((resolve, reject) => {
      const user = Pool.getCurrentUser()
      if (user) {
        user.getSession(async (error: Error | null, session: CognitoUserSession) => {
          if (error) {
            reject("Error getting user session")
          } else {
            const attributes: any = await new Promise((resolve, reject) => {
              user.getUserAttributes((attributesError, attributesReply) => {
                if (attributesError) {
                  reject("Error getting user attributes")
                } else {
                  const results: any = {}
                  if (attributesReply) {
                    for (let key of attributesReply) {
                      const { Name, Value } = key
                      results[Name] = Value
                    }
                  }
                  resolve(results)
                }
              })
            })
            resolve({ user, session, attributes })
          }
        })
      } else {
        reject("You are not authenticated")
      }
    })
  }

  useEffect(() => {
    getSession().then(() => {
      setLoggedIn(true)
    })
  }, [])

  const authenticate = async (Username: string, Password: string) => {
    return await new Promise((resolve, reject) => {
      const loginUser = new CognitoUser({ Username, Pool })
      const authDetails = new AuthenticationDetails({ Username, Password })
      loginUser.authenticateUser(authDetails, {
        onSuccess: (reply) => {
          console.log("Success", reply)
          resolve(reply)
        },
        onFailure: (error) => {
          console.error("Failure", error)
          reject(error)
        },
        newPasswordRequired: (reply) => {
          console.warn("NewPass", reply)
          resolve(reply)
        },
      })
    })
  }

  const logOut = () => {
    const user = Pool.getCurrentUser()
    if (user) {
      user.signOut()
    }
  }

  const changePassword = (oldPassword: string, newPassword: string) => {
    getSession()
      .then((sessionReply) => {
        sessionReply.user.changePassword(oldPassword, newPassword, (error, reply) => {
          if (error) {
            console.log({ error })
          } else {
            console.log({ reply })
          }
        })
      })
      .catch((sessionError) => {
        console.log("User is not logged in or has no session", sessionError)
      })
  }

  const changeEmail = (email: string, password: string) => {
    getSession()
      .then((sessionReply) => {
        authenticate(email, password).then(() => {
          const attributes = [new CognitoUserAttribute({ Name: "email", Value: email })]
          sessionReply.user.updateAttributes(attributes, (updateError, updateReply) => {
            if (updateError) {
              console.log(updateError)
            } else {
              console.log(updateReply)
            }
          })
        })
      })
      .catch((sessionError) => {
        console.log("User is not logged in or has no session", sessionError)
      })
  }

  return <InitialContext.Provider value={{ loggedIn, authenticate, logOut, changePassword }}>{children}</InitialContext.Provider>
}
