import React from "react"
import { createRoot } from "react-dom/client"

// https://mui.com/material-ui/getting-started/installation/#roboto-font
import "@fontsource/roboto/300.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/500.css"
import "@fontsource/roboto/700.css"

// Routes
import App from "./App"

const root = createRoot(document.getElementById("root") as HTMLElement)

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
