import { FormEvent, useState } from "react"

import { AccountContext } from "./Account"

function Status() {
  const [oldPass, setOldPass] = useState("")
  const [newPass, setNewPass] = useState("")

  const { loggedIn, logOut, changePassword } = AccountContext()

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    changePassword?.(oldPass, newPass)
  }

  return (
    <div>
      {loggedIn ? <button onClick={logOut}>Logout</button> : "You are not logged in."}

      <br />

      {loggedIn && (
        <div>
          <h2>Settings</h2>
          <form onSubmit={onSubmit}>
            <label htmlFor="OldPass">Old Password</label>
            <input value={oldPass} onChange={(event) => setOldPass(event.target.value)} />
            <br />
            <label htmlFor="NewPass">New Password</label>
            <input value={newPass} onChange={(event) => setNewPass(event.target.value)} />
            <br />
            <button type="submit">Submit</button>
          </form>
        </div>
      )}
    </div>
  )
}

export default Status
