import SignUp from "./components/SignUp"
import Status from "./components/Status"

import { AccountWrapper } from "./components/Account"

function App() {
  return (
    <AccountWrapper>
      <Status />
      <SignUp />
      <SignUp isLogin />
    </AccountWrapper>
  )
}

export default App
